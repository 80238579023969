import { enableProdMode } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';

import { AppModule } from './app/app.module';
import { environment } from './environments/environment';

// ! Se coloca esto para añadir por defecto la configurcion de mapbox, es lo mas recomendado
import mapboxgl from 'mapbox-gl';
mapboxgl.accessToken = 'pk.eyJ1IjoibHVpc3BlcmRvbW80MDgiLCJhIjoiY2xqaXpnY2o3MDQ3eTNncGI1YWN5eW1qZiJ9.IjpfPbexwEjcLNQ6JTt_1w';

if (environment.production) {
  enableProdMode();
  // console.log =  () => { }
  // console.error = () => { }
  // console.warn = () => { }
}
console.log = function() {}
console.error =  function() {}
console.warn = function() {}



// ! Importante: no borrar esta validacion verifica si el navegador es compatible con geolocation
if( !navigator.geolocation ) {
  throw new Error('El navegador no soporta el Geolocation');
}

platformBrowserDynamic().bootstrapModule(AppModule)
  .catch(err => console.error(err));

