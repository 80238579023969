import { Component, ComponentRef, HostListener, inject, OnInit, Type, ViewContainerRef } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { IModule, INotification } from 'src/app/core/interfaces/IModule.interface';
import { PushNotificationService } from 'src/app/services/pushNotification.service';
import { filter, map, distinctUntilChanged } from 'rxjs/operators';
import { ObservableComponentsService } from 'src/app/services/observable-components.service';
import { DashboardModulesService } from 'src/app/core/services/dashboard-modules.service';
import { SesionstorageService } from 'src/app/services/sesionstorage.service';
@Component({
  selector: 'app-notifications',
  templateUrl: './notifications.component.html',
  styleUrls: ['./notifications.component.scss']
})
export class NotificationsComponent  implements OnInit{
  constructor(
    private Router: Router,
    public _notfication: PushNotificationService,
    private _sessionStorage: SesionstorageService,
    private obsServer: ObservableComponentsService
  ) { }
  public vcr = inject(ViewContainerRef);
  dropdownOpen = false;
  public notifications: INotification[] = [];
  @HostListener('document:click', ['$event'])
  onClick(event: Event): void { 
    const target = event.target as HTMLElement;
  const dropdown = document.querySelector('#dropdown-modal-nodtification');
  const iconContent = document.querySelector('#icon-contendt-notification');

  if (dropdown && iconContent) {
    const clickedInsideDropdown = dropdown.contains(target);
    const clickedInsideIcon = iconContent.contains(target);

    // Cierra el dropdown si el clic no es dentro del dropdown o del ícono
    if (!clickedInsideDropdown && !clickedInsideIcon) {
      this.dropdownOpen = false;
    }
  }
  }

  toggleDropdown(): void {
 this.dropdownOpen = !this.dropdownOpen;
 if(!this.dropdownOpen) this._notfication.reloadMessagesNotReaded();
  }
   
  public modalMode = false;
  public ngOnInit() {
    this._notfication.initSocket();
  }
   
  handleActiveMessage(message: any) {
    console.log(message);
    
    const content: any = document.getElementById(`item_id_${message.id}`);

    if (content.className.includes('item_closed')) {
      content.classList.remove('item_closed')
      content.classList.add('item_active')
    }
    else {
      content.classList.remove('item_active')
      content.classList.add('item_closed')
    }

    if (!message.read_at) {
      this._notfication.markMessageRead(message);
    }
  }
  redirectNotification(event:Event,message: any){
    event.stopPropagation();
    
    const url = message.routerLink;
    
    console.log(url);
    //DESESTRUCTURAMOS EL URL OBTENIDO DEL ADMINISTRADOR
    const [urlAdmin,qParams] = url.split("?--");
    if(qParams){
      const dataConcatenada = qParams.split(`=`);
      //CREAMOS EL OBJETO NECESARIO PARA DESESTRUCTURAR
      let object = {[dataConcatenada[0]]: dataConcatenada[1]};
      console.log(object);
      //REESTRUCUTURACION
     this._sessionStorage.SetSesionStorage("dataRedirection",JSON.stringify(object));
      this.Router.navigate([`./dashboard/${urlAdmin}`])
      this.obsServer.notificationButton$.emit(object);
    }
    this.dropdownOpen = false;
    this._notfication.markMessageRead(message);
  }
  markAllAsRead() {
    this._notfication.notificationNotRead.forEach((message: any, index: number) => {
      this._notfication.markAllAsRead(message, index);
    });
  }
  public viewModalNotification(){
  
    this.modalMode = true;
  //  this.loadComponent(ModalNotificationsComponent);
  }
  public closeModalNotiifications(){
    this.modalMode=false
  }
  public showMoreNotRead(){
    const user = this._sessionStorage.GetSesionStorage("login").Data;
      console.log(user);
      
      if(user) {
        this._notfication.loadMoreMessagesUnreaded(user.idUser ?? user.idEmpConv);
        // this.cdr.detectChanges();
      }
  }  
}
